// {[...Array(26).keys()].map((i) => (
//   <>
//     {`/Recursos/Recurso ${i + 1}.png`}
//     <img style="maxWidth:80vw;" src={`/Recursos/Recurso ${i + 1}.png`} />
//   </>
// ))}
// import Landing from 'components/Landing';
import dynamic from "next/dynamic";
import gsap from "gsap";
// const Landing = dynamic(() => import('../components/Landing'), { ssr: false });
const Slider = dynamic(() => import("components/slider"), {
  ssr: false,
});
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useState, useEffect } from "react";
import LandingNavBar from "components/layout/landingNavBar";
import MenuBar from "components/layout/menuBar";
import GoogleMapComponent from "components/layout/Maps";
export default function index() {
  const [overlay, setOverlay] = useState(false);
  // return <Landing /
  useEffect(() => {
    setTimeout(() => {
      gsap.to("#theContent", { duration: 0.6, opacity: 1 });
    }, 1000);
  }, []);
  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div>...Loading</div>;
      case Status.FAILURE:
        return;
      case Status.SUCCESS:
        return <GoogleMapComponent />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        background: "rgba(10,15,15)",
      }}
    >
      <LandingNavBar setOverlay={setOverlay} />
      <div
        id="theContent"
        style={{ position: "relative", flex: 1, overflow: "hidden" }}
      >
        {overlay && <MenuBar />}

        <div
          style={{
            maxHeight: "100%",
            height: "100%",
            position: "relative",
            flex: 1,
            overflowY: "scroll",
          }}
        >
          <Slider />
          <div
            style={{
              background: "rgba(10,15,15)",
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h2> {`VIVE EXPERIENCIAS EXTRAORDINARIAS`}</h2>
              <h2> {`SOMOS APACIONADOS POR EL SERVICIO`}</h2>
              <h2> {`VISITANOS`}</h2>
            </div>
          </div>
          <div class="agenda">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                // justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  pointerEvents: "none",
                  zIndex: 1,
                  border: "solid 4px rgba(160,160,160,0.8)",
                  borderRadius: "10px",
                  background: "rgba(10,10,10,0.6)",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    color: "white",
                    // mixBlendMode: "exclusion",
                    fontSize: "200%",
                    fontWeight: 10,
                    textShadow: "0 0 20px white",
                    color: "rbg(240,170,50)",
                  }}
                >
                  ¡ENCUENTRANOS AQUI!🐦
                </div>
              </div>
            </div>
            {/* <img
              style={{ height: "400px", width: "100%,", objectFit: "cover" }}
              src="/banner.png"
            /> */}
            <GoogleMapComponent />
          </div>
          <div
            style={{
              height: "400px",
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <h1>Registrate</h1> */}
            <div
              style={{
                textAlign: "center",
                fontSize: "250%",
                fontWeight: 1000,
              }}
            >
              ¿QUIERES VISITAR CALI?
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "250%",
                fontWeight: 1000,
              }}
            >
              💃🏽🐦💕👨‍⚕️
            </div>
            <h2>¡Esta es tu oportunidad!</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "800px",
              margin: "auto",
              textAlign: "center",
            }}
          >
            {/* <h1>RESIDENCIAS VEGA</h1> */}
            <p></p>
            <div
              class="offerings"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div>
                <div>🎒</div> <div>Locacion pefecta para viajeros</div>
              </div>
              <div>
                <div>🖥️</div>
                Monitoreo 24/7
              </div>
              <div>
                <div>📈</div>
                <div> Popularidad</div>
              </div>
              <div>
                <div>🔒</div>
                Seguridad y Privacidad
              </div>
              <div>
                <div>🏛️ </div>
                Instalaciones de Alto Perfil
              </div>
              <div>
                <div>💼</div>
                Pensado para ejecutivos
              </div>
              <div>
                <div>🗺️</div>
                Viajes
              </div>
              <div>
                <div>🍲</div>
                Servicios especiales
              </div>
              <div>
                <div>💵</div>
                Precio conveniente
              </div>
            </div>
            <h1>VEN Y DEJATE ATENDER</h1>
            <button>REGISTRATE </button>
          </div>
        </div>
      </div>
      <div
        class="footer"
        style={{
          fontWeight: "1000",
          display: "flex",
          alignItems: "center",
          gap: "20px",
          borderTop: "solid 3px rgb(80,80,80)",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "20px",
            alignItems: "center",
          }}
        >
          <img
            style={{ transform: "scale(0.5)", height: "50px" }}
            src="/footerHouse.png"
          />
          <div>SIGUENOS</div>
        </div>
        <div class="icons">
          <div>
            <a
              className="block hover:text-slate-200"
              href="https://instagram.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/instagram2.png" height={35} width={35}></img>
            </a>
          </div>
          <div>
            <a
              className="block hover:text-slate-200"
              href="https://facebook/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/facebook-color-svgrepo-com.png"
                height={30}
                width={30}
              ></img>
            </a>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
    </div>
  );
}
export async function getServerSideProps() {
  return {
    props: { hello: "world" },
  };
}

import React, { useState } from "react";

export default function LandingNavBar({ setOverlay }) {
  return (
    <div>
      <div
        style={{
          zIndex: 55,
          padding: "10px",
          maxHeight: "60px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          color: "silver",
          background: "black",
          borderBottom: "solid 4px rgb(80,80,80)",
          padding: "10px",
          marginBottom: "1.2rem",
        }}
      >
        <img
          style={{
            borderRadius: "40%",
            background: "rgba(10,10,10,0.9)",
            position: "absolute",
            height: "90px",
            maxHeight: "100px",
            top: "5px",
            left: "5px",
          }}
          src="VegaHausen.png"
        />
        <div style={{ flex: 1, fontSize: "2rem" }} />
        {/* <h1 style="flex:1;">IMPRERIAL</h1> */}
        <div onClick={() => setOverlay((prev) => !prev)}>MENU</div>
      </div>
      ;
    </div>
  );
}

import React from "react";
import { useRouter } from "next/navigation";

export default function MenuBar() {
  const router = useRouter();
  const handleNavigation = (path) => {
    router.push(path);
  };
  return (
    <div>
      (
      <div className="menu" style={{ display: "flex", gap: "20px" }}>
        <div onClick={() => handleNavigation("/")}>
          <div>INICIO</div>
        </div>
        <div onClick={() => handleNavigation("/nosotros")}>
          <div>NOSOTROS</div>
        </div>
        {/* <div onClick={() => handleNavigation("/reservas")}>
              <div>RESERVAS</div>
            </div> */}
        <div onClick={() => handleNavigation("/admin")}>
          <div>INGRESA</div>
        </div>
        <div onClick={() => handleNavigation("/contacto")}>
          <div>CONTACTO</div>
        </div>
      </div>
      )
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const locations = [
  { key: "Chipichape", location: { lat: 3.474979, lng: -76.527681 } },
  { key: "La topa tolondra", location: { lat: 3.443434, lng: -76.53658 } },
  { key: "Rio pance", location: { lat: 3.342744, lng: -76.665449 } },
  { key: "Museo la tertulia", location: { lat: 3.45053, lng: -76.545571 } },
  { key: "Granada", location: { lat: 3.462039, lng: -76.532833 } },
  { key: "Cervezas Calima", location: { lat: 3.449856, lng: -76.54156 } },
  { key: "San Antonio", location: { lat: 3.446488, lng: -76.539789 } },
  { key: "Boulevard del rio", location: { lat: 3.452699, lng: -76.534849 } },
  {
    key: "Terminal de trasportes",
    location: { lat: 3.465845, lng: -76.522863 },
  },
];

const mapContainerStyle = {
  width: "95%",
  height: "360px",
};

const center = {
  lat: 3.4682,
  lng: -76.532,
};

const GoogleMapComponent = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    version: "weekly",
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBT_j424yLV6RK071qd6pavoVB7dbW3oOw",
  });

  const PoiMarkers = ({ locations }) => {};
  const [attractions, setAttractions] = useState([]);

  useEffect(() => {
    if (!isLoaded) return;

    const map = new window.google.maps.Map(document.createElement("div"), {
      center,
      zoom: 15,
    });
  }, [isLoaded]);

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;
  setAttractions(locations);
  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={13} center={center}>
      <MarkerF
        position={center}
        key={"vega Residencias"}
        label={{ text: "🐦 Vega Residencias " }}
        cursor="pointer"
        icon={{
          url: "/twitter.svg", // URL to a custom icon
          scaledSize: new google.maps.Size(40, 40), // Adjust the size of the icon
        }}
      />
      {locations.map((poi) => (
        <MarkerF key={poi.key} position={poi.location} label={poi.key} />
      ))}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
// "use client";

// import React, { useEffect, useState } from "react";

// // const mapContainerStyle = {
// //   width: "95%",
// //   height: "360px",
// // };

// const center = {
//   lat: 3.4682,
//   lng: -76.532,
// };

// const GoogleMapComponent = () => {
//   ((g) => {
//     var h,
//       a,
//       k,
//       p = "The Google Maps JavaScript API",
//       c = "google",
//       l = "importLibrary",
//       q = "__ib__",
//       m = document,
//       b = window;
//     b = b[c] || (b[c] = {});
//     var d = b.maps || (b.maps = {}),
//       r = new Set(),
//       e = new URLSearchParams(),
//       u = () =>
//         h ||
//         (h = new Promise(async (f, n) => {
//           await (a = m.createElement("script"));
//           e.set("libraries", [...r] + "");
//           for (k in g)
//             e.set(
//               k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
//               g[k]
//             );
//           e.set("callback", c + ".maps." + q);
//           a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
//           d[q] = f;
//           a.onerror = () => (h = n(Error(p + " could not load.")));
//           a.nonce = m.querySelector("script[nonce]")?.nonce || "";
//           m.head.append(a);
//         }));
//     d[l]
//       ? console.warn(p + " only loads once. Ignoring:", g)
//       : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
//   })({
//     key: process.env.GOOGLE_API_KEY,
//     v: "weekly",
//     // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
//     // Add other bootstrap parameters as needed, using camel case.
//   });
//   let map;
//   async function initMap() {
//     const { Map } = await google.maps.importLibrary("maps");

//     map = new Map(document.getElementById("map"), {
//       center: { center },
//       zoom: 8,
//     });
//   }

//   initMap();

//   return <div id="map"></div>;
// };
// export default GoogleMapComponent;
